export default {
    lang: "O'zbekcha",
    header: {
        nav_platon: "Platon",
        nav_possibilities: "Imkoniyatlar",
        nav_integration: "Integratsiya",
        nav_suggestions: "Ko’rsatmalar",
        nav_cases: "Keyslar",
        nav_support: "Yordam",
        nav_blog: "Blog",
        nav_login: "Kirish",
        nav_documents: "Hujjatlar",
    },
    intro: {
        subtitle: "Platon.uz bilan loyihalarni <br> tez va samarali yarating",
        title: "Loyihalarni yaratish va joriy etish uchun innovatsion mahsulotlarni tadbiq etishga mo’ljallangan low-code platforma",
        fulldocs: "To'liq hujjatlar",
        workwith: "Bog'lanish",
        apply: "Ariza berish",
    },
    possbs: {
        title: "Imkoniyatlar",
        subtitle: "Platformaning <span>keng funksional</span> imkoniyatlari, <span>ko'plab muammolarni</span> hal qilishga yordam beradi.",
        desc: "Framework da yirik elektron axborot tizimlari, maʼlumotlar bazalarini tez, sifatli, qo‘shimcha sarf-xarajatlarsiz ishlab chiqish, boshqa axborot tizimlari bilan bog‘lash hamda mustaqil takomillashtirib borishga mo‘ljallangan dasturiy mahsulotlarni yaratish imkoniyatlari mavjud.",
        fordevs_title: "Dasturchilar uchun imkoniyatlar ",
        fordevs_desc:
            "Platon dasturchisi boshqa platformalardan va dasturlash tillaridagi ishlab chiqarishdan farqli ravishda o’z ish jarayonida Platonning konstruktoridan yaqindan foydalanadi. Konstruktor shunday ishlab chiqilganki, dasturchi unda fronted uchun faqatgina HTML/ CSS/ JS va Vue JS kod yoziladi. Shunda ham dasturchidan yuqorida sanab o’tilgan tillarni chuqur bilishlarii talab etilmaydi. Dasturchi faqat eng asosiy mantiqni bajaradigan kodni yozadi. Qolgan amaliyotlarning barchasini platformaning Core qismi avtomatik ravishda bajaradi. ",
        fordevs_subtitle: "Endilikda dasturlar ishlab chiqish ancha tez va qulay",
        fordevs_adv1_title: "Maxsus yechimlarning imkoniyatlari",
        fordevs_adv1_desc: "Loyihalarni yaratish va joriy etish uchun innovatsion mahsulotlarni  tadbiq etishga mo’ljallangan low-code platforma hisoblanadi.",
        fordevs_adv2_title: "Tasdiqlangan sahifalarning shakllari",
        fordevs_adv2_desc: "Platforma har qanday qurilmada muammosiz ishlaydi. Foydalanuvchi  kompyuter, planshet yoki smartfondan foydalanayotganida platforma unga juda tez moslasha oladi.",
        fordevs_adv3_title: "Bulutli yechimlar va serverlar bilan ishlash",
        fordevs_adv3_desc: "Loyihalarni yaratish va joriy etish uchun innovatsion mahsulotlarni tadbiq etishga mo’ljallangan low-code platforma hisoblanadi.",
        fordevs_more: "Imkoniyatlar haqida ko'proq ma'lumot",

        optimise_title: "Optimallashtirish imkoniyatlari",
        optimise_desc:
            "Platforma o’z o’rnida ma’lumotlar bazasi, fayllar, xotira maydoni va API ga o’zi avtomatik ulanadi hamda amaliyotlarning hammasini avtomatlashtirilgan ravishda o’zi bajaradi. Dasturchidan esa faqatgina shu mantiqni ko’rsatib berish talab qilinadi. Tayyorlangan dasturga oddiy foydalanuvchi o’z login va paroli bilan ulanganidan so’ng, tizimda ishlab chiqilgan funksiyalardan bemalol foydalanadi. Platon platformasining himoya qatlami foydalanuvchining tiplariga qarab, kerakli ma’lumotlarni o’zi ko’rsata oladi. Buning uchun tizimda ruxsatlarni boshqaruvchi kuchli mexanizm mavjud.",

        apis_title: "API imkoniyatlarini amalga oshirish",
        apis_desc: "Platon platformasining ichki va tashqi murojaatlari uchun mukammal ishlab chiqilgan API mexanizmiga ega. Dasturchi istalgan tartibda, istalgan ko’rinishda API Endpointlar ishlab chiqib uni platforma ichida uzviy ishlashini ta’minlashi mumkin. Bundan tashqari API ENGINE dasturchiga bir necha bosqichli murojaatlarni ham bajarib, ularning ma’lumotlarini istalgan ko’rinishda qaytarish qobilyatiga ega.",
    },
    integrs: {
        title: "Integrasiya",
        subtitle: "Turli xizmat va mahsulotlar bilan <br><span>integratsiya qilish imkoniyati</span>",
        desc: "Platon platformasini har qanday integratsiya qilish mumkin bo’lgan tizim, xizmat va mahsulotlar ya’ni tashqi resurslar bilan bog'lash mumkin.",
        socials_title: "Ijtimoiy tarmoqlar va sun’iy intellekt <br> bilan avtorizatsiya qilish imkoniyati ",
        socials_desc: "Avtorizasiya platformamizning eng muhim qismidir, biz eng maksimal darajada moslashuvchan avtorizasiya tizimlarini taklif qilamiz. Ular orasida Google, Facebook, Apple, Face-ID, E-imzo, RS-imzo va boshqalar mavjud.",
        langs_title: "Platformada qo'llaniladigan dasturiy tillar",
        langs_desc: "Platformada Java, JavaScript, SQL (Multiple Databases), Vue.js, HTML, Pure JavaScript va CSS dasturiy tillaridan foydalaniladi.",
        libs_title: "Kutubxona va belgilarni ulash",
        libs_desc: "Platformada kutubxona va belgilar (иконка) ni ulash, <br> ularni turli joylarga qo’yish imkoniyatlari mavjud.",
    },
    instrs: {
        title: "KO’RSATMALAR",
        subtitle: "Platformadan <span>oddiy foydalanish</span> qoidalari",
        desc: "Platformada ish olib boradigan dasturchidan, dasturlashni mukammal bilish talab etilmaydi, chunki aksariyat amallar platforma tomonidan avtomatik ravishda bajariladi. Shuningdek, platformada ortiqcha kod yozish ham shart emasligi, bajariladigan ishlar odatiy dasturlashdan 3-5 barobar tez amalga oshadi. Aksariyat amaliyotlar faqat bir platforma tomonidan bajarilganligi uchun, dasturlash bosqichida katta jamoa talab etilmaydi. Shuning uchun loyihaga ortiqcha xarajatlar talab etilmaydi. No-code dasturlash uslubidan farqli o’laroq, Low-code uslubda kerakli joylarda foydalanuvchi o’z kodini tizimda bemalol qo’llashi mumkin. Shuning uchun ishlab chiqilgan axborot tizimi qulay  va boshqariluvchan bo’ladi.",
        fulldocs: "To'liq hujjatlar",
        ins_addfiles_title: "Fayllarni qo’shish",
        ins_addfiles_desc: "Platformadagi “Fayl menejeri” moduli yordamida siz o’zingiz yaratayotgan sayt yoki turli xil loyihalarga fayllar qo’shishingiz yoki ularni boshqarishingiz mumkin. Qolaversa, platformada papkalar yaratib fayllarning vazifasi qo’shilgan ma’lumotlarni ko’rsatish, papkalarda nechta fayllar borligini ko’rish imkoniyatlari mavjud.  ",
        ins_addcodes_title: "Kodlarni qo’shish va tahrirlash ",
        ins_addcodes_desc: "Platformada Java, JavaScript, SQL (Multiple Databases), Vue.js, HTML, Pure JavaScript va CSS dasturiy tillaridan foydalangan holda kodlar qo’shish va tahrirlash imkoniyati mavjud. Agarda siz biror bir funksiya qo’shmoqchi bo’lsangiz, lekin ushbu funksiya Platon da bo’lmasa js eval ga o’tkazib olib, yangi kod yozishingiz mumkin. Javob ham siz xohlaganday bo’ladi. Qaytgan javob esa API integratsiya orqali kerakli joyga yetib boradi.   ",
        ins_integrations_title: "Boshqa serverlar bilan integratsiya",
        ins_integrations_desc: "-",
        ins_clouds_title: "Bulutli xizmatlar bilan ishlash",
        ins_clouds_desc: "-",
        ins_designs_title: "Mavzulardagi dizaynni o’zgartirish",
        ins_designs_desc: "Platformada o’zingizga ma’qul bo’lgan dizayn mavzusini tanlashingiz va o’zgartirishingiz mumkin. Shuningdek",
    },
    projects: {
        title: "TUGALLANGAN LOYIHALAR",
        subtitle: "<span>Platon.uz</span> platformasining imkoniyatlari bilan keng masshtabli va <span>turli murakkablikdagi loyihalar</span> bajarildi.",
        desc: "Bugungi kunda maktab va maktabgacha ta’lim, xodimlar boshqaruvi, qishloq xo’jaligi, mahalla, bank kabi  turli xil sohalarda Platon.uz platformasi tomonidan yaratilgan tizimlardan foydalanilmoqda.",
        project_onlinemahalla: '"OnlineMahalla" - elektron platforma',
        project_onlinemahalla_desc: "-",

        project_oilakredit: '"OilaKredit" - elektron platforma',
        project_oilakredit_desc: "-",

        project_nodavlat: '"NodavlatBog’cha" - elektron platforma',
        project_nodavlat_desc: "-",

        project_smartagro: '"SmartAgro" - elektron platforma',
        project_smartagro_desc: "-",

        project_agroonline: '"AgroOnline" - elektron platforma',
        project_agroonline_desc: "-",

        project_brb: '"BiznesPortal" - elektron platforma',
        project_brb_desc: "-",

        project_hujjat: '"Hujjat.uz" - elektron platforma',
        project_hujjat_desc: "-",

        project_onlinebozor: '"OnlineBozor" - elektron platforma',
        project_onlinebozor_desc: "-",

        project_smartmarket: '"SmartMarket" - elektron platforma',
        project_smartmarket_desc: "-",

        project_smartoffice: '"SmartOffice" - elektron platforma',
        project_smartoffice_desc: "-",

        project_suvkredit: '"SuvKredit" - elektron platforma',
        project_suvkredit_desc: "-",

        project_xotinqiz: '"Xotin-qizlar.uz" - elektron platforma',
        project_xotinqiz_desc: "-",

        // kirirtilmagan
        project_raqamlimaktab: '"Raqamli-maktab" - elektron platforma',
        project_raqamlimaktab_desc: "-",

        project_ecoservis: '"Eco-service.uz" - elektron platforma',
        project_ecoservis_desc: "-",

        project_keeping: '"Kiping.uz" - elektron platforma',
        project_keeping_desc: "-",

        project_harvest: '"Harvest.uz" - elektron platforma',
        project_harvest_desc: "-",
    },
    support: {
        title: "YORDAM",
        subtitle: "<span>Platon.uz</span> da tez-tez uchraydigan muammolarni hal qilish uchun <span>video yordam</span>",
        desc: "Saytdagi yangilanishlarni amalda qo’llash uchun yangiliklardan xabardor bo’lib boring.",
    },
    blog: {
        title: "BLOG",
        subtitle: "Siz uchun sohamiz va Platon platformamizga oid bo'lgan <span>so'nggi yangiliklar</span>",
        desc: "Yangiliklarimizni kuzatib boring.",
    },
    footer: {
        desc: "Loyihalarni yaratish va joriy etish uchun innovatsion mahsulotlarni tadbiq etishga mo’ljallangan low-code platforma",
        dep_title: "Bo’lim",
        dep_possbs: "Imkoniyatlar",
        dep_integrs: "Integratsiyalar",
        acts_title: "Harakatlar",
        acts_docs: "Hujjatlar",
        acts_instrs: "Yo’riqnomalar",
        acts_support: "Yordam",
        contacts_title: "Aloqa",
        contacts_address: "O’zbekiston, Toshkent shahar, Kichik halqa yo’li 38/1",
        copyright: "2003-%crntyear REALSOFT Barcha huquqlar himoyalangan",
    },
};
