import router from "@/router/index";

export default function scrollToSection(id) {
    console.log(router);
    if (router.currentRoute.name !== "home") {
        router.push({name: "home"}).then(() => {
            setTimeout(() => {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                });
            }, 1000);
        });
    } else {
        document.getElementById(id).scrollIntoView({
            behavior: "smooth",
        });
    }
}
