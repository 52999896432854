import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import scrollToSection from "./helpers/scrollToSection.js";
import i18n from "./i18n";

import "@/assets/sass/common.scss";

Vue.config.productionTip = false;
Vue.prototype.$scrollToSection = scrollToSection;

Vue.directive("scroll", {
    inserted: function (el, binding) {
        const delay = binding.value || "0s"; // Use the provided delay or default to '0s'
        console.log(delay);
        el.style.animationDelay = delay;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    el.classList.add("pl-animated");
                    observer.unobserve(el);
                }
            },
            {threshold: 0.1},
        );

        observer.observe(el);
    },
});

new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
