<template>
    <header>
        <div
            class="rs-header"
            :class="{scrolled: isScrolled, opened: isMobile}">
            <div class="rs-header__content container">
                <div class="rs-header__logo">
                    <a
                        class="rs-header__logo-link"
                        @click="$scrollToSection('introduction'), (isMobile = false)">
                        <img
                            class="rs-header__logo-image"
                            src="@/assets/images/logo.svg"
                            alt="Unisale logo" />
                    </a>
                </div>

                <div class="rs-header__lang">
                    <div class="rs-lang__holder">
                        <div class="rs-lang__toggler">
                            <img
                                src="@/assets/icons/icon-glove.svg"
                                alt="globe"
                                class="rs-lang__toggler-icon" />
                        </div>
                        <div class="rs-lang__body">
                            <div class="rs-lang__list">
                                <a
                                    @click="changeLocal('ru')"
                                    href="#!"
                                    class="rs-lang__item">
                                    Русский
                                </a>
                                <a
                                    @click="changeLocal('la')"
                                    href="#!"
                                    class="rs-lang__item">
                                    O'zbekcha
                                </a>
                                <a
                                    @click="changeLocal('uz')"
                                    href="#!"
                                    class="rs-lang__item">
                                    Узбекча
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="rs-header__nav">
                    <ul class="rs-header__nav-links">
                        <li class="rs-header__link">
                            <a
                                @click="$scrollToSection('introduction', (isMobile = false))"
                                :data-text="$t('header.nav_platon')"
                                class="rs-header__link-txt">
                                {{ $t("header.nav_platon") }}
                            </a>
                        </li>
                        <li class="rs-header__link">
                            <a
                                @click="$scrollToSection('possibilities', (isMobile = false))"
                                :data-text="$t('header.nav_possibilities')"
                                class="rs-header__link-txt">
                                {{ $t("header.nav_possibilities") }}
                            </a>
                        </li>
                        <li class="rs-header__link">
                            <a
                                @click="$scrollToSection('integrations', (isMobile = false))"
                                :data-text="$t('header.nav_integration')"
                                class="rs-header__link-txt">
                                {{ $t("header.nav_integration") }}
                            </a>
                        </li>
                        <li class="rs-header__link">
                            <a
                                @click="$scrollToSection('instructions', (isMobile = false))"
                                :data-text="$t('header.nav_suggestions')"
                                class="rs-header__link-txt">
                                {{ $t("header.nav_suggestions") }}
                            </a>
                        </li>
                        <li class="rs-header__link">
                            <a
                                @click="$scrollToSection('projects', (isMobile = false))"
                                :data-text="$t('header.nav_cases')"
                                class="rs-header__link-txt">
                                {{ $t("header.nav_cases") }}
                            </a>
                        </li>
                        <li class="rs-header__link">
                            <a
                                @click="$scrollToSection('support', (isMobile = false))"
                                :data-text="$t('header.nav_blog')"
                                class="rs-header__link-txt">
                                {{ $t("header.nav_blog") }}
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="rs-header__actions">
                    <CompButton
                        class="rs-header__action-btn"
                        :rsbtnText="$t('header.nav_flaer')"
                        :rsbtnLink="'/data/platon.pdf'"
                        :rsbtnTarget="true"
                        :rsbtnType="'rs-secondary'"
                        download />
                    <CompButton
                        class="rs-header__action-btn"
                        :rsbtnText="$t('header.nav_documents')"
                        :rsbtnLink="'https://doc.platon.uz/ru/home'"
                        :rsbtnTarget="true"
                        :rsbtnLeftIcon="'icon-document'" />
                </div>

                <div class="rs-header__mobile">
                    <span
                        class="rs-header__mobile-btn"
                        @click="openMobile"></span>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import CompButton from "@/components/comp-unibtn.vue";

export default {
    name: "section-header",
    components: {
        CompButton,
    },
    data() {
        return {
            isScrolled: false,
            isMobile: false,
        };
    },
    mounted() {
        ["mousewheel", "scroll"].forEach((evt) => window.addEventListener(evt, this.handleScroll, false));
    },
    methods: {
        handleScroll() {
            let screenWidth = window.innerWidth;
            if (screenWidth < 992) return;

            if (window.pageYOffset > 90) {
                this.isScrolled = true;
            } else if (this.isScrolled && window.pageYOffset <= 90 && window.pageYOffset > 1) {
                return;
            } else {
                this.isScrolled = false;
            }
        },
        changeLocal(lang) {
            this.$locale.change(lang);
        },
        openMobile() {
            this.isMobile = !this.isMobile;
        },
    },
};
</script>
