<template>
    <a
        :href="rsbtnLink"
        :target="{_blank: rsbtnTarget}"
        class="rsbtn hoverable"
        :class="[rsbtnType]">
        <img
            v-if="rsbtnLeftIcon"
            class="rsbtn__icon left"
            :src="require(`@/assets/icons/${rsbtnLeftIcon}.svg`)"
            alt="button icon" />
        <p class="rsbtn__text">{{ rsbtnText }}</p>
        <img
            v-if="rsbtnRightIcon"
            class="rsbtn__icon right"
            :src="require(`@/assets/icons/${rsbtnRightIcon}.svg`)"
            alt="button icon" />
    </a>
</template>

<script>
export default {
    name: "compButton",
    props: {
        rsbtnType: {
            type: String,
        },
        rsbtnText: {
            type: String,
            required: true,
        },
        rsbtnLink: {
            type: String,
            default: "/",
        },
        rsbtnLeftIcon: {
            type: String,
        },
        rsbtnRightIcon: {
            type: String,
        },
        rsbtnTarget: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
