export default {
    lang: "Русский",
    header: {
        nav_platon: "Платон",
        nav_possibilities: "Возможности",
        nav_integration: "Интеграция",
        nav_suggestions: "Инструкции",
        nav_cases: "Кейсы",
        nav_support: "Поддержка",
        nav_blog: "Блог",
        nav_login: "Вход",
        nav_documents: "Документы",
        nav_flaer: "Флаер",
    },
    intro: {
        subtitle: "Создавайте проекты с Platon.uz <br> быстро и эффективно",
        title: "Low-code Платформа, предназначенная для разработки инновационных цифровых продуктов, и внедрения готовых решений",
        fulldocs: "Полная документация",
        workwith: "Связаться",
        apply: "Подать заявку",
    },
    possbs: {
        title: "Возможности",
        subtitle: "Обширный функционал платформы <span>помогает решить</span> <span>множество задач</span>.",
        desc: "Framework предоставляет возможности для быстрого, качественного, без дополнительных затрат разработки крупных информационных систем, баз данных, их интеграции с другими информационными системами и самостоятельного совершенствования программных продуктов.",
        fordevs_title: "Возможности для разработчиков",
        fordevs_desc: "Разработчик Platon, в отличие от других платформ и языков программирования, использует конструктор Platon в своем рабочем процессе. Конструктор разработан таким образом, что разработчик пишет код только для фронтенда с использованием HTML/CSS/JS и Vue JS. Глубокие знания этих языков не требуются, разработчик пишет только основной логический код. Все остальные операции автоматически выполняются ядром платформы.",
        fordevs_subtitle: "Теперь разработка программ стала быстрее и удобнее",
        fordevs_adv1_title: "Возможности специализированных решений",
        fordevs_adv1_desc: "Low-code Платформа, предназначенная для разработки инновационных цифровых продуктов, и внедрения готовых решений.",
        fordevs_adv2_title: "Интеграция с существующими системами",
        fordevs_adv2_desc: "Low-code Платформа предоставляет возможности для бесшовной интеграции с вашими текущими системами и сервисами, что позволяет быстро адаптировать новые решения к вашему бизнес-процессу и улучшить производительность.",
        fordevs_adv3_title: "Работа с облачными решениями и серверами",
        fordevs_adv3_desc: "Low-code Платформа, предназначенная для разработки инновационных цифровых продуктов, и внедрения готовых решений.",
        fordevs_more: "Подробнее о возможностях",

        optimise_title: "Возможности оптимизации",
        optimise_desc: "Платформа автоматически подключается к базам данных, файлам, памяти и API, выполняя все операции автоматически. Разработчику требуется только указать логику. Пользователь может использовать разработанные функции после входа в систему с помощью логина и пароля. Слой безопасности платформы Platon автоматически отображает необходимую информацию в зависимости от типа пользователя. В системе существует мощный механизм управления правами доступа.",

        apis_title: "Реализация возможностей API",
        apis_desc: "Платформа Platon имеет усовершенствованный механизм API для внутренних и внешних запросов. Разработчик может создавать API Endpoint любого типа и обеспечивать их работу внутри платформы. Кроме того, API ENGINE позволяет выполнять многоэтапные запросы и возвращать данные в любом виде.",
    },
    integrs: {
        title: "Интеграция",
        subtitle: "Возможность интеграции <br><span>с различными услугами и продуктами</span>",
        desc: "Платформу Platon можно интегрировать с любыми системами, услугами и продуктами, то есть внешними ресурсами.",
        socials_title: "Возможность авторизации <br> через социальные сети и искусственный интеллект",
        socials_desc: "Авторизация является важной частью нашей платформы. Мы предлагаем максимально гибкие системы авторизации, включая Google, Facebook, Apple, Face-ID, E-imzo, RS-imzo и многие другие.",
        langs_title: "Используемые языки программирования",
        langs_desc: "На платформе используются Java, JavaScript, SQL (Multiple Databases), Vue.js, HTML, чистый JavaScript и CSS.",
        libs_title: "Подключение библиотек и иконок",
        libs_desc: "Возможности подключения библиотек и иконок (иконка) и их размещения в различных местах.",
    },
    instrs: {
        title: "ИНСТРУКЦИИ",
        subtitle: "Правила <span>простого использования</span> платформы",
        desc: "От разработчика, работающего на платформе, не требуется глубоких знаний программирования, так как большинство операций выполняется автоматически. Также нет необходимости писать лишний код, так как работа выполняется в 3-5 раз быстрее, чем при обычном программировании. Поскольку большинство операций выполняется одной платформой, для разработки проекта не требуется большая команда, что снижает затраты. В отличие от no-code, low-code позволяет пользователю вставлять собственный код в нужных местах, что делает разработанные информационные системы удобными и управляемыми.",
        fulldocs: "Полная документация",
        ins_addfiles_title: "Добавление файлов",
        ins_addfiles_desc: "С помощью модуля “Файловый менеджер” вы можете добавлять и управлять файлами для создаваемых вами сайтов или различных проектов. Также можно создавать папки, видеть количество файлов в папках и управлять ими.",
        ins_addcodes_title: "Добавление и редактирование кода",
        ins_addcodes_desc: "На платформе можно добавлять и редактировать код с использованием Java, JavaScript, SQL (Multiple Databases), Vue.js, HTML, чистого JavaScript и CSS. Если вы хотите добавить функцию, которой нет в Platon, вы можете использовать js eval для написания нового кода. Ответ будет таким, как вам нужно, и достигнет нужного места через интеграцию API.",
        ins_integrations_title: "Интеграция с другими серверами",
        ins_integrations_desc: "Платформа Platon предоставляет возможность интеграции с другими организациями через модуль “API” для проектов, создаваемых с её помощью. Этот модуль позволяет легко организовать, внедрить и запустить данные и методы, указанные в “Технических инструкциях”, предоставленных организациями, в различных форматах.",
        ins_clouds_title: "Работа с облачными сервисами",
        ins_clouds_desc: "Платформа Platon позволяет создавать программы и демонстрировать их через интернет, а также создавать их виртуальные модели.",
        ins_designs_title: "Изменение дизайна тем",
        ins_designs_desc: "На платформе вы можете выбрать и изменить понравившуюся вам тему дизайна.",
    },
    projects: {
        title: "ЗАВЕРШЕННЫЕ ПРОЕКТЫ",
        subtitle: "Платформа <span>Platon.uz</span> позволила выполнить <span>широкомасштабные проекты различной сложности</span>.",
        desc: "Сегодня платформу Platon.uz используют в различных областях, таких как школа и дошкольное образование, управление персоналом, сельское хозяйство, махалля, банки и другие.",

        project_onlinemahalla: '"OnlineMahalla" - электронная платформа',
        project_onlinemahalla_desc: "Электронная платформа, предусматривающая цифровизацию деятельности центра махалли и собрания граждан махалли, связанной между собой.",

        project_oilakredit: '"OilaKredit" - электронная платформа',
        project_oilakredit_desc: "Программа, направленная на создание условий для дополнительного и стабильного дохода населения путем их широкого привлечения к предпринимательской деятельности, ремесленничеству, кустарному производству и другим сферам предпринимательской деятельности.",

        project_nodavlat: '"NodavlatBog’cha" - электронная платформа',
        project_nodavlat_desc: "Комплексная информационная система для цифровизации деятельности негосударственных дошкольных образовательных учреждений в рамках государственно-частного партнерства, их финансовой поддержки государством и мониторинга учебно-воспитательного процесса в режиме реального времени.",

        project_smartagro: '"SmartAgro" - электронная платформа',
        project_smartagro_desc: "Система, оказывающая практическую помощь и координирующая расчеты в производстве сельскохозяйственной продукции в республике.",

        project_agroonline: '"AgroOnline" - электронная платформа',
        project_agroonline_desc: "Система, оказывающая практическую помощь и координирующая расчеты в производстве сельскохозяйственной продукции в республике.",

        project_brb: '"BiznesPortal" - электронная платформа',
        project_brb_desc: "Малые предпринимательские субъекты, участвующие в комплексной программе «Непрерывная поддержка малого бизнеса», отбираются на основе принципов прозрачности.",

        project_hujjat: '"Hujjat.uz" - электронная платформа',
        project_hujjat_desc: "Информационная система, служащая для быстрого и эффективного обмена бумажными документами на электронные.",

        project_onlinebozor: '"OnlineBozor" - электронная платформа',
        project_onlinebozor_desc: "Система, обеспечивающая удобство поиска и покупки различных товаров, от необходимых для повседневных нужд до редких, с простым процессом покупки и продажи.",

        project_smartmarket: '"SmartMarket" - электронная платформа',
        project_smartmarket_desc: "-",

        project_smartoffice: '"SmartOffice" - электронная платформа',
        project_smartoffice_desc: "Комплексная система, предоставляющая информацию о сотрудниках организации, структурных подразделениях, номенклатуре должностей, вакансиях, кадровом резерве, системе рекомендаций на государственные награды, отпусках, командировках, повышении квалификации, табелях, больничных листах, материальной помощи, днях рождения, истории должностей, целевой подготовке кадров, оценке и стимулировании производительности труда сотрудников.",

        project_suvkredit: '"SuvKredit" - электронная платформа',
        project_suvkredit_desc: "Сервис для онлайн-кредитования сельскохозяйственных производителей, намеревающихся внедрять водосберегающие технологии.",

        project_xotinqiz: '"Xotin-qizlar.uz" - электронная платформа',
        project_xotinqiz_desc: "Система, направленная на цифровизацию направления финансовой помощи нуждающимся женщинам от Фонда помощи женщинам.",

        project_raqamlimaktab: '"Raqamli-maktab" - электронная платформа',
        project_raqamlimaktab_desc: "-",

        project_ecoservis: '"Eco-service.uz" - электронная платформа',
        project_ecoservis_desc: "Программная система, созданная для цифровизации государственной экологической экспертизы.",

        project_keeping: '"Kiping.uz" - электронная платформа',
        project_keeping_desc: "-",

        project_harvest: '"Harvest.uz" - электронная платформа',
        project_harvest_desc: "-",
    },
    support: {
        title: "ПОДДЕРЖКА",
        subtitle: "Видео помощь <span>для решения частых проблем на Platon.uz</span>",
        desc: "Следите за новостями, чтобы применять обновления на сайте.",
    },
    blog: {
        title: "БЛОГ",
        subtitle: "Последние новости нашей специализации <span>для постоянного информирования о Platon.uz</span>",
        desc: "Следите за новостями.",
    },
    footer: {
        desc: "Low-code Платформа, предназначенная для разработки инновационных цифровых продуктов, и внедрения готовых решений",
        dep_title: "Раздел",
        dep_possbs: "Возможности",
        dep_integrs: "Интеграции",
        acts_title: "Действия",
        acts_docs: "Документы",
        acts_instrs: "Инструкции",
        acts_support: "Поддержка",
        contacts_title: "Контакты",
        contacts_address: "Узбекистан, г. Ташкент, Кичик халка йули 38/1",
        copyright: "2003-%crntyear REALSOFT Все права защищены",
    },
};
