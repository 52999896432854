<template>
    <footer class="footer">
        <div class="footer__content container">
            <div class="footer__info">
                <a
                    class="footer__info-logo"
                    href="#!">
                    <img
                        src="@/assets/images/logo.svg"
                        alt="logo"
                        class="footer__info-logo" />
                </a>
                <p class="footer__info-txt">{{ $t("footer.desc") }}</p>

                <div class="footer__info-socials">
                    <a
                        href="https://www.facebook.com/platon.framework/"
                        target="_blank">
                        <img
                            class="footer__info-social"
                            src="@/assets/icons/icon-social-facebook.svg"
                            alt="icon" />
                    </a>
                    <a
                        href="https://www.instagram.com/platon_framework/"
                        target="_blank">
                        <img
                            class="footer__info-social"
                            src="@/assets/icons/icon-social-instagram.svg"
                            alt="icon" />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/platon-uz/"
                        target="_blank">
                        <img
                            class="footer__info-social"
                            src="@/assets/icons/icon-social-linkedin.svg"
                            alt="icon" />
                    </a>
                    <a
                        href="https://t.me/platon_uz"
                        target="_blank">
                        <img
                            class="footer__info-social"
                            src="@/assets/icons/icon-social-telegram.svg"
                            alt="icon" />
                    </a>
                </div>
            </div>
            <div class="footer__links">
                <p class="footer__links-title">{{ $t("footer.dep_title") }}</p>
                <a
                    @click="$scrollToSection('introduction')"
                    class="footer__link">
                    {{ $t("header.nav_platon") }}
                </a>
                <a
                    @click="$scrollToSection('possibilities')"
                    class="footer__link">
                    {{ $t("header.nav_possibilities") }}
                </a>
                <a
                    @click="$scrollToSection('integrations')"
                    class="footer__link">
                    {{ $t("header.nav_integration") }}
                </a>
                <a
                    @click="$scrollToSection('instructions')"
                    class="footer__link">
                    {{ $t("header.nav_suggestions") }}
                </a>
                <a
                    @click="$scrollToSection('projects')"
                    class="footer__link">
                    {{ $t("header.nav_cases") }}
                </a>
                <a
                    @click="$scrollToSection('support')"
                    class="footer__link">
                    {{ $t("header.nav_blog") }}
                </a>
            </div>
            <div class="footer__links">
                <p class="footer__links-title">{{ $t("footer.acts_title") }}</p>
                <a
                    class="footer__link"
                    href="#!">
                    {{ $t("footer.acts_docs") }}
                </a>
                <a
                    class="footer__link"
                    href="#!">
                    {{ $t("footer.acts_instrs") }}
                </a>
                <a
                    class="footer__link"
                    href="#!">
                    {{ $t("footer.acts_support") }}
                </a>
            </div>
            <div class="footer__links rs-last">
                <p class="footer__links-title">{{ $t("footer.contacts_title") }}</p>
                <a
                    class="footer__link"
                    href="mailto:info@platon.uz">
                    info@platon.uz
                </a>
                <a
                    class="footer__link"
                    href="tel:+998787778484">
                    +99878 777 84 84
                </a>
                <a
                    class="footer__link"
                    href="#!">
                    {{ $t("footer.contacts_address") }}
                </a>
            </div>
        </div>
        <div class="footer__copyright">
            <p class="footer__copyright-txt">
                {{ $t("footer.copyright").replace("%crntyear", new Date().getFullYear()) }}
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "section-footer",
};
</script>
